<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="code"
          label="name"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.type"
          :options="option.typePayment"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          :show-labels="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ typePayment[props.option] }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ typePayment[props.option] }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Account Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-validate="'required'" v-model="create.accountName" />
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Account Number</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-validate="'required'"
          v-model="create.accountNumber"
          
        />
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Issuer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full" v-if="create.type != 3">
        <multiselect
          class="selectExample"
          v-model="create.issuer"
          :options="option.bank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="Name"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col sm:w-2/3 w-full" v-if="create.type == 3">
        <multiselect
          class="selectExample"
          v-model="create.issuer"
          :options="option.ewallet"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="Name"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Branch</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.bankBranch"
          
        />
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Swift Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.swiftCode"
          
        />
      </div>
    </div>

    <br />
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import {
  dataCustomer,
} from "../../../../services/api/payment";
import {
  dataBank,
  dataEWallet,
  postPaymentAccount,
  putPaymentAccount,
} from "../../../../services/api/payment_account";
export default {
  props: {
    url: {
      type: String
    },
    id: {
      type: Number
    },
  },
  data() {
    return {
      // 1: Real Account,2: VA, 3: Wallet
      typePayment: ["", "Real Account", "VA", "Wallet"],
      create: {
        accountNumber: null,
        accountName: null,
        customer: null,
        typePayment: null,
        issuer: null,
        notes: null,
        id: null,
        type: null,
        customerId: null,
        bankId: null,
        bankBranch: null,
        swiftCode: null,
      },
      option: {
        customer: [],
        typePayment: [1, 2, 3],
        bank: [],
        ewallet: [],
      },
    };
  },
  mounted() {
    this.getCustomer();
    this.getBank();
    this.getEWallet();
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    id(){
      if (this.id) {
        this.getData();
      }else{
        this.create =  {
          accountNumber: null,
          accountName: null,
          customer: null,
          typePayment: null,
          issuer: null,
          notes: null,
          id: null,
          type: null,
          customerId: null,
          bankId: null,
          bankBranch: null,
          swiftCode: null,
        }
      }
    }
  },
  methods: {
    closeDetail() {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    handleSubmit() {
      this.$vs.loading();
      let post;
        const request = {
          type: this.create.type,
          customer_code: this.create.customer.code,
          customer_name: this.create.customer.name,
          issuer: this.create.issuer.Name,
          account_name: this.create.accountName,
          account_number: this.create.accountNumber,
          customer_id: this.create.customer.ID,
          bank_branch: this.create.bankBranch,
          swift_code: this.create.swiftCode,
        }; 

        if (this.id) {
          post = putPaymentAccount(this.id, request);
        } else {
          post = postPaymentAccount(request);
        }
        
      post.then((r) => {
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });
    },
    formatNumber(string) {
        if(string) {
            return parseFloat(string.toString().replace(/\./g, ""));
        } else {
            return 0;
        }
    },
    getCustomer() {
      this.$vs.loading();
      const customer = dataCustomer();
      customer.then((response) => {
        this.option.customer = [];
        response.data.customer.map((row) => {
          this.option.customer.push(row);
        });
        this.$vs.loading.close();
      });
    },
    getBank() {
      this.$vs.loading();
      const bank = dataBank();
      bank.then((response) => {
        this.option.bank = [];
        response.data.records.map((row) => {
          this.option.bank.push(row);
        });
        this.$vs.loading.close();
      });
    },
    getEWallet() {
      this.$vs.loading();
      const ewallet = dataEWallet();
      ewallet.then((response) => {
        this.option.ewallet = [];
        response.data.records.map((row) => {
          this.option.ewallet.push(row);
        });
        this.$vs.loading.close();
      });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.create.id = resp.data.payment.ID;
            this.create.accountName = resp.data.payment.AccountName;
            this.create.accountNumber = resp.data.payment.AccountNumber;
            this.create.issuer = {Name:resp.data.payment.Issuer};
            this.create.customer = resp.data.customer;
            this.create.type = resp.data.payment.Type;
            this.create.bankBranch = resp.data.payment.BankBranch;
            this.create.swiftCode = resp.data.payment.SwiftCode;   
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
};
</script>